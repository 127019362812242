import { createWebHistory, createRouter } from "vue-router";

import store from "../store/index";
import User from "../services/user-services";

const routes = [
  {
    path: "/",
    query: {mail_token: ''},
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cambiar",
    query: {token: ''},
    name: "cambiar",
    component: () => import("../views/CambiarClave.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/rastreoPedido",
    query: {qr_code: ''},
    name: "rastreoPedido",
    component: () => import("../views/RastreoPedido.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/libro_rec",
    name: "libro_rec",
    component: () =>
        import("../views/LibroReclamaciones.vue"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/unsubscribed",
    name: "unsubscribed",
    component: () =>
        import("../views/Unsubscribed.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/apply_job",
    name: "apply_job",
    component: () =>
        import("../views/ApplyJob.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/trabajo",
    name: "trabajo",
    component: () =>
        import("../views/ApplyJob.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/restaurantes/:idLocal-:texto",
    name: "CartaRestaurante",
    component: () =>
        import(
            "../views/CartaRestaurante.vue"
            ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/menudigital",
    name: "menudigital",
    component: () =>
        import(
            "../views/NuestraCarta.vue"
            ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/menudigital/:idCategory",
    name: "menudigitalcat",
    component: () =>
        import(
            "../views/NuestraCarta.vue"
            ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/menudigital/:idCategory/:idProduct",
    name: "menudigitalprod",
    component: () =>
        import(
            "../views/NuestraCarta.vue"
            ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/micarrito/pagar",
    name: "micarrito.pagar",
    component: () =>
        import(
            "../views/PaymentScreen.vue"
            ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/perfil",
    name: "perfil",
    component: () =>
        import("../views/Perfil/Perfil.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "cuenta",
        component: () => import("../views/Perfil/PerfilMiCuenta.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "perfil.cuenta",
            component: () => import("../views/Perfil/PerfilMiCuentaUser.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "direcciones",
            name: "perfil.cuenta.direcciones",
            component: () =>
                import("../views/Perfil/PerfilMiCuentaLugares.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "clave",
        name: "perfil.clave",
        component: () => import("../views/Perfil/PerfilCambiarClave.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "beneficios",
        name: "perfil.beneficios",
        component: () => import("../views/Perfil/PerfilMisBeneficios.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "notificaciones",
        name: "perfil.notificaciones",
        component: () => import("../views/Perfil/PerfilMisGestionNotificaciones.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "pedidos",
        name: "pedidos",
        component: () => import("../views/Perfil/PerfilMisPedidos.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "misreservas",
        name: "misreservas",
        component: () => import("../views/Perfil/PerfilMisReservas.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "direcciones",
        name: "direcciones.solo",
        component: () => import("../views/Perfil/PerfilMiCuentaLugares.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/locales",
    name: "locales",
    component: () => import("../views/Cobertura.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reservas",
    name: "reservas",
    component: () => import("../views/Reservas.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/every_ui_kit",
    name: "every_ui_kit",
    component: () => import("../views/every_ui_kit.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError(error => {
  let lowercase = error.message.toLowerCase()
  console.log("el error del router es", lowercase)
  let condition = (lowercase).includes('chunk failed')
  let condition2 = (lowercase).includes('loading chunk')
  let condition3 = (lowercase).includes('loading css chunk')
  let condition4 = (lowercase).includes('loading css')

  console.log("contiene texto?", condition)
  console.log("contiene texto2?", condition2)
  console.log("contiene texto3?", condition2)
  if (condition || condition2 || condition3 || condition4)  {
    setTimeout(() => {
      window.alert('Se actualizará la web. Por favor, vuelva a intentarlo cuando termine de cargar.')
      window.location.reload()
    }, 1900)
  } else {
    localStorage.clear()
    window.location.reload()
  }
})

let firtOcurrency = true;
async function loadLocalStorageData() {
  let orderDirection = await JSON.parse(localStorage.getItem("orderDirection"));
  if (orderDirection) {
    store.commit("setOrderDirection", orderDirection);
  }
  let orderLocal = await JSON.parse(localStorage.getItem("orderLocal"));
  if (orderLocal) {
    store.commit("setOrderLocal", orderLocal);
  }
  let listLocals = await JSON.parse(localStorage.getItem("listLocals"));
  if (listLocals) {
    store.commit("setListLocals", listLocals);
  }
  let typeOrder = await JSON.parse(localStorage.getItem('typeOrder'));
  if (typeOrder) {
    store.commit('setTypeOrder', typeOrder)
  }
  let shoppingCartInfo = await JSON.parse(localStorage.getItem('shoppingCartInfo'));
  if (shoppingCartInfo) {
    store.commit('setShoppingCartInfo', shoppingCartInfo)
  }
  let webVersion = await JSON.parse(localStorage.getItem("webVersion"));
  if (webVersion) {
    store.commit("setWebVersion", webVersion)
  }
  let businessInfo = await JSON.parse(localStorage.getItem("businessInfo"));
  if (businessInfo) {
    store.commit("setBusinessInfo", businessInfo)
  }
  let user = await JSON.parse(localStorage.getItem("user"));
  if (user) {
    await User.setAuthHeaders(user.token);
    store.commit("setUser", user);
    store.commit("setAuthenticatedTrue");
  } else {
    store.commit("setAuthenticatedFalse");
    //router.push("/")
  }
}

router.beforeEach(async (to, from, next) => {
  if (firtOcurrency) {
    await loadLocalStorageData();
    firtOcurrency = false;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // si es una ruta protegida
      // const accessToken = await JSON.parse(localStorage.getItem('accessToken')) // no funcionaba el $localStorage
      // const user = await JSON.parse(localStorage.getItem('user'))
      if (store.state.isAuthenticated) {
        // si esta logueado
        // verificar su rol
        next();
      } else {
        await router.push("/");
      }
      //next()
    } else {
      // ruta no esta protegida
      next();
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // si es una ruta protegida
      // const accessToken = await JSON.parse(localStorage.getItem('accessToken')) // no funcionaba el $localStorage
      // const user = await JSON.parse(localStorage.getItem('user'))
      if (store.state.isAuthenticated) {
        // si esta logueado
        // verificar su rol
        next();
      } else {
        await router.push("/");
      }
      //next()
    } else {
      // ruta no esta protegida
      next();
    }
  }
});
export default router;
