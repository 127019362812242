import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import "@/assets/css/qe_datepicker.css"
import "@/assets/css/ui_kit_quickeat.css"
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import * as uiv from 'uiv'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VueGtag from "vue-gtag"
import VueGoogleMaps from '@fawmi/vue-google-maps';

const app = createApp(App).use(i18n)

import Vue3NativeNotification from 'vue3-native-notification'
app.use(Vue3NativeNotification, {
    requestOnNotify: true
})

app.use(uiv)
app.use(Vue3Lottie)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places'

    },
})

// Plugins
app.use(ToastPlugin, {
    position: 'top-right',
    duration: 5000
});
app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID }
})

// AOS initialization
app.mixin({
    created() {
        AOS.init({
            duration: 1000
        })
    }
})

// Router and Store
app.use(router)
app.use(store)

import * as Sentry from "@sentry/vue";
import i18n from './i18n'
if (process.env.NODE_ENV !== 'development')  {
    console.log("importa sentry mainjs")
    Sentry.init({
        app,
        dsn: "https://1da32c178db647f4b40035057482838c@o1061511.ingest.sentry.io/6097575",
    });
}

// Mount app
app.mount('#app')
